<script>
  import store from '@/store'
  import router from '@/router/index'
 

  export default {
    name: 'Logout',

    beforeRouteEnter(to, from, next) {
      store.dispatch('auth/logout'); 
      router.push('/auth/login');
     // document.location.reload()
      next()
      
    }

  }
</script>